import * as React from 'react';
import { 
  Dimensions, 
  Image, 
  Button, 
  SafeAreaView, 
  View, 
  FlatList, 
  StyleSheet, 
  Text,
  Linking,
} from 'react-native';
import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';

import { Platform } from 'react-native';
import { WebView as RNWebView } from 'react-native-webview';
import WWebView from 'WebView';
// import ListData from './data/ListData.json';

import Constants from 'expo-constants';
// import { TouchableOpacity } from 'react-native-gesture-handler';

// const DATA = require("./data/list_data.json");

const WebView = Platform.OS === 'web' ? WWebView : RNWebView;

class HomeScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.makeRemoteRequest();
  }

  makeRemoteRequest = () => {
    const url = `https://berniereport.com/data/list_data.json`;
    fetch(url)
      .then(res => res.json())
      .then(res => {
        this.setState({
          data: res
        });
      })
  };

  render() {
    return (

      <SafeAreaView style={styles.container}>
        <View style={{width: "100%", height: "100%", 
                  flexDirection: 'column', 
                  justifyContent: 'center',
                alignItems: 'center',
      
      }}>
      <FlatList
      style={{
        width: Math.round(Math.min(Dimensions.get('window').width, Dimensions.get('window').height))
      }}
        //  data={DATA}
        data={this.state.data}
        renderItem={({ item }) => 
        // <TouchableOpacity
        // onPress={() => this.props.navigation.navigate('report', {
        //   url: item.url,
        // })}
        // >
          <View style={{ 
            width: "100%",
            flexDirection: 'column', 
            justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
          paddingHorizontal: 0,      
          paddingTop: Math.round(
            Math.min(Dimensions.get('window').width, Dimensions.get('window').height) * 0.03 
          ),      
          paddingBottom: Math.round(
            Math.min(Dimensions.get('window').width, Dimensions.get('window').height) * 0.02 
          ),      
          marginVertical: "2%",
            // width: Math.round(
            //   ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / item.im_width) * 0.88) *  item.im_width 
            // ), 
            // height: Math.round(
            //   ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / item.im_width) * 0.88) * item.im_height 
            // ), 
          }}>
          <Image
          style={{
            width: Math.round(
              ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / item.im_width) * 0.94) *  item.im_width 
            ), 
            height: Math.round(
              ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / item.im_width) * 0.94) * item.im_height 
            ), 
          }}
          source={{uri: item.image}}
        />
      {/* <View style={
        { width: Math.round(
          ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / item.im_width) * 0.94) *  item.im_width 
        ),
        }
        }> */}
      <Text style={[
        styles.title,
        { maxWidth: Math.round(
          ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / item.im_width) * 0.94) *  item.im_width 
        ),
        }  
      ]}>{item.title}</Text>
      {/* </View> */}
      <Button
          title="go"
          onPress={() => Linking.openURL(item.url)}
          // onPress={() => this.props.navigation.navigate('report', {
          //   url: item.url,
          // })}
        />
    </View>
    // </TouchableOpacity>
        }
        keyExtractor={item => item.id}
      />
      </View>
    </SafeAreaView>

    );
  }
}

class DetailsScreen extends React.Component {


  render() {
    const { navigation } = this.props;
    return (

      <WebView
        source={{uri: navigation.getParam('url', 'https://example.com')}} 
      />

      // <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      //   <Text>Details Screen</Text>
      // </View>
    );
  }
}

const RootStack = createStackNavigator(
  {
    'BernieReport.com': HomeScreen,
    report: DetailsScreen,
  },
  {
    initialRouteName: 'BernieReport.com',
  }
);

const AppContainer = createAppContainer(RootStack);

export default class App extends React.Component {
  render() {
    return <AppContainer />;
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: Constants.statusBarHeight,
  },
  item: {
    backgroundColor: '#fff',
    padding: 0,
    marginVertical: "6%",
    marginHorizontal: "6%",
  },
  title: {
    fontSize: 26,
    marginVertical: "2%",
    marginHorizontal: "0%",
  },
  marginz: {
    marginVertical: "2%",
    marginHorizontal: "0%",
  },
});

//////////////////////////////////////////////////////////////

// import React from 'react';
// import { SafeAreaView, View, FlatList, StyleSheet, Text } from 'react-native';
// import Constants from 'expo-constants';

// const DATA = [
//   {
//     id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
//     title: 'First Item',
//   },
//   {
//     id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
//     title: 'Second Item',
//   },
//   {
//     id: '58694a0f-3da1-471f-bd96-145571e29d72',
//     title: 'Third Item',
//   },
// ];

// function Item({ title }) {
//   return (
//     <View style={styles.item}>
//       <Text style={styles.title}>{title}</Text>
//     </View>
//   );
// }

// export default function App() {
//   return (
//     <SafeAreaView style={styles.container}>
//       <FlatList
//         data={DATA}
//         renderItem={({ item }) => <Item title={item.title} />}
//         keyExtractor={item => item.id}
//       />
//     </SafeAreaView>
//   );
// }

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     marginTop: Constants.statusBarHeight,
//   },
//   item: {
//     backgroundColor: '#f9c2ff',
//     padding: 20,
//     marginVertical: 8,
//     marginHorizontal: 16,
//   },
//   title: {
//     fontSize: 32,
//   },
// });


/////////////////////////////////////////


// import React from 'react';
// import { Platform } from 'react-native';
// import { WebView as RNWebView } from 'react-native-webview';
// import WWebView from 'WebView';

// const WebView = Platform.OS === 'web' ? WWebView : RNWebView;

// export default function App() {
//   return (

//     <WebView
//         source={{uri: 'https://example.com'}}
//       />

//   );
// }

/////////////////////////////////////////////

// import React from 'react';
// import { StyleSheet, Text, View } from 'react-native';

// export default function App() {
//   return (
//     <View style={styles.container}>
//       <Text>Open up App.js to start working on your app!</Text>
//     </View>
//   );
// }

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: '#fff',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
// });
